<template>
  <main id="rDomain">

    <div class="d-flex justify-content-center mb-3">
      <router-link to="/client/domainBfDetails">
        <span id="backBtn" class="myBtn">
          <i class="bi bi-arrow-left"></i>
        </span>
      </router-link>
      <!--  -->
      <!--  -->
      <span id="downloadPDF">
        <a>Dernière étape d'enregistrement du nom de domaine</a>
      </span>
    </div>

    <center>
      <form v-if="data.natureRegister == 'PERSON'" class="row" @submit.prevent="validatePerson()">
        <h4 class="text-center mb-3">Document d'identification du particulier</h4>
        <!--  -->
        <div id="process" class="mb-3">
          <b>Important*: </b> <br>
          Les recto et verso doivent figurer sur une seule image ou dans un un même document PDF.
        </div>

        <table class="mb-3 table table-bordered">
          <tbody>
            <tr>
              <td class="align-middle">
                <div class="text-center">
                  Pièce d'identité
                  <br>
                  ou passport
                </div>
              </td>
              <td class="text-center">
                <img id="img1" v-if="img1Src && isImg(doc1)" class="border border-dark" :src="img1Src"
                  style="width: 112px;" />
                <svg v-else-if="img1Src && !isImg(doc1)" height="112px" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z" />
                </svg>
                <!--  -->
                <!-- <svg v-else height="112px" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 20 18">
                  <path fill="currentColor"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                </svg> -->
                <div v-else class="d-flex noPreview">
                  <div class="w-100 text-center noPrev">
                    Aucun fichier
                    <br>
                    sélectionné
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <div class="btn btn-primary btn-rounded">
                  <label class="form-label text-white m-1" for="doc1">Importer un fichier</label>
                  <input type="file" class="form-control d-none" id="doc1" @input="getImg1Src('doc1')"
                    accept=".pdf, .jpg, .jpeg" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!--  -->
        <!--  -->
        <div id="process" class="mb-3">
          <b>Important*: </b> <br>
          Importer le formulaire téléchargé, signé et cacheté avec la mention lu et approuvé.
        </div>

        <table class="mb-3 table table-bordered">
          <tbody>
            <tr>
              <td class="align-middle">
                <div class="text-center">
                  Formulaire du nom
                  <br>
                  de domaine
                </div>
              </td>
              <td class="text-center">
                <svg v-if="pdfForm" height="112px" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 16 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z" />
                </svg>
                <!--  -->
                <div v-else class="d-flex noPreview">
                  <div class="w-100 text-center noPrev">
                    Aucun fichier
                    <br>
                    sélectionné
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <div class="btn btn-primary btn-rounded">
                  <label class="form-label text-white m-1" for="docForm">Importer un fichier</label>
                  <input type="file" class="form-control d-none" id="docForm" accept=".pdf"
                    @input="getPdfForm('docForm')" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!--  -->
        <!--  -->
        <div class="col-12 d-flex justify-content-center">
          <button type="submit">
            VALIDER LES DOCUMENTS ET TERMINER
            <i class="bi bi-check-lg"></i>
          </button>
        </div>
      </form>



      <form v-else-if="data.natureRegister == 'STRUCTURE'" class="row" @submit.prevent="validateStruct()">
        <h4 class="text-center mb-3">Document d'identification de la structure</h4>
        <!--  -->
        <div id="process" class="mb-3">
          <b>Important*: </b> <br>
          Les recto et verso doivent figurer sur une seule image ou dans un un même document PDF.
        </div>

        <table class="mb-3 table table-bordered">
          <tbody>
            <tr>
              <td class="align-middle">
                <div class="text-center">
                  Preuve d'existance de la structure
                  <br>
                  (IFU, RCCM, Récépissé...)
                </div>
              </td>
              <td class="text-center">
                <img id="img1" v-if="img1Src && isImg(doc1)" class="border border-dark" :src="img1Src"
                  style="width: 112px;" />
                <svg v-else-if="img1Src && !isImg(doc1)" height="112px" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z" />
                </svg>
                <!--  -->
                <!-- <svg v-else height="112px" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 20 18">
                  <path fill="currentColor"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                </svg> -->
                <div v-else class="d-flex noPreview">
                  <div class="w-100 text-center noPrev">
                    Aucun fichier
                    <br>
                    sélectionné
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <div class="btn btn-primary btn-rounded">
                  <label class="form-label text-white m-1" for="sDoc1">Importer un fichier</label>
                  <input type="file" class="form-control d-none" id="sDoc1" @input="getImg1Src('sDoc1')"
                    accept=".pdf, .jpg, .jpeg" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!--  -->
        <!--  -->
        <div id="process" class="mb-3">
          <b>Important*: </b> <br>
          Importer le formulaire téléchargé, signé et cacheté avec la mention lu et approuvé.
        </div>

        <table class="mb-3 table table-bordered">
          <tbody>
            <tr>
              <td class="align-middle">
                <div class="text-center">
                  Formulaire du nom
                  <br>
                  de domaine
                </div>
              </td>
              <td class="text-center">
                <svg v-if="pdfForm" height="112px" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 16 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z" />
                </svg>
                <!--  -->
                <div v-else class="d-flex noPreview">
                  <div class="w-100 text-center noPrev">
                    Aucun fichier
                    <br>
                    sélectionné
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <div class="btn btn-primary btn-rounded">
                  <label class="form-label text-white m-1" for="sDocForm">Importer un fichier</label>
                  <input type="file" class="form-control d-none" id="sDocForm" accept=".pdf"
                    @input="getPdfForm('sDocForm')" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!--  -->
        <!--  -->
        <div class="col-12 d-flex justify-content-center">
          <button type="submit">
            VALIDER LES DOCUMENTS ET TERMINER
            <i class="bi bi-check-lg"></i>
          </button>
        </div>
      </form>


      <form v-else class="row" @submit.prevent="validatePrest()">
        <h4 class="text-center mb-3">Document d'identification du prestataire</h4>
        <!--  -->
        <div id="process" class="mb-3">
          <b>Important*: </b> <br>
          Les recto et verso doivent figurer sur une seule image ou dans un un même document PDF.
        </div>

        <table class="mb-3 table table-bordered">
          <tbody>
            <tr>
              <td class="align-middle">
                <div class="text-center">
                  Preuve d'existance de la structure
                  <br>
                  (IFU, RCCM, Récépissé...)
                </div>
              </td>
              <td class="text-center">
                <img id="img1" v-if="img1Src && isImg(doc1)" class="border border-dark" :src="img1Src"
                  style="width: 112px;" />
                <svg v-else-if="img1Src && !isImg(doc1)" height="112px" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z" />
                </svg>
                <!--  -->
                <!-- <svg v-else height="112px" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 20 18">
                  <path fill="currentColor"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                </svg> -->
                <div v-else class="d-flex noPreview">
                  <div class="w-100 text-center noPrev">
                    Aucun fichier
                    <br>
                    sélectionné
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <div class="btn btn-primary btn-rounded">
                  <label class="form-label text-white m-1" for="pDoc1">Importer un fichier</label>
                  <input type="file" class="form-control d-none" id="pDoc1" @input="getImg1Src('pDoc1')"
                    accept=".pdf, .jpg, .jpeg" />
                </div>
              </td>
            </tr>

            <tr>
              <td class="align-middle">
                <div class="text-center">
                  Mandat
                  <br>
                  du client
                </div>
              </td>
              <td class="text-center">
                <img id="img1" v-if="img2Src && isImg(doc2)" class="border border-dark" :src="img2Src"
                  style="width: 112px;" />
                <svg v-else-if="img2Src && !isImg(doc2)" height="112px" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z" />
                </svg>
                <!--  -->
                <!-- <svg v-else height="112px" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 20 18">
                  <path fill="currentColor"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                </svg> -->
                <div v-else class="d-flex noPreview">
                  <div class="w-100 text-center noPrev">
                    Aucun fichier
                    <br>
                    sélectionné
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <div class="btn btn-primary btn-rounded">
                  <label class="form-label text-white m-1" for="pDoc2">Importer un fichier</label>
                  <input type="file" class="form-control d-none" id="pDoc2" @input="getImg2Src('pDoc2')"
                    accept=".pdf, .jpg, .jpeg" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!--  -->
        <!--  -->
        <div id="process" class="mb-3">
          <b>Important*: </b> <br>
          Importer le formulaire téléchargé, signé et cacheté avec la mention lu et approuvé.
        </div>

        <table class="mb-3 table table-bordered">
          <tbody>
            <tr>
              <td class="align-middle">
                <div class="text-center">
                  Formulaire du nom
                  <br>
                  de domaine
                </div>
              </td>
              <td class="text-center">
                <svg v-if="pdfForm" height="112px" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 16 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z" />
                </svg>
                <!--  -->
                <div v-else class="d-flex noPreview">
                  <div class="w-100 text-center noPrev">
                    Aucun fichier
                    <br>
                    sélectionné
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <div class="btn btn-primary btn-rounded">
                  <label class="form-label text-white m-1" for="pDocForm">Importer un fichier</label>
                  <input type="file" class="form-control d-none" id="pDocForm" accept=".pdf"
                    @input="getPdfForm('pDocForm')" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!--  -->
        <!--  -->
        <div class="col-12 d-flex justify-content-center">
          <button type="submit">
            VALIDER LES DOCUMENTS ET TERMINER
            <i class="bi bi-check-lg"></i>
          </button>
        </div>
      </form>
    </center>

  </main>
</template>


<script>
const $ = require("jquery");
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
import functions from "/src/common/js/functions";
import axios from "axios";
import popup from "/src/common/js/popup";
//
import * as localforage from "localforage";
// import html2pdf from "html2pdf.js";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";

//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      //
      pageName: "Document d'identification (.bf)",
      hasEnterprise: false,
      //
      data: {},
      //
      userData: {
        id: "",
        fname: "",
        email: "",
        tel: "",
        home_address: "",
        country: "",
        city: '',
      },
      //
      img1Src: "",
      doc1: null,
      img2Src: "",
      doc2: null,
      pdfForm: null,
      formIsBusy: false,
      //
      domainName: "---",
    };
  },

  mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    //
    //
    localforage.getItem('ecocloud/User').then(async (result) => {
      let jsData = result;
      if (jsData == "" || jsData == "{}" || jsData == null) {
        //
        this.$router.replace("/login");
        return;
      }
      try {
        jsData = JSON.parse(jsData);
      }
      catch (e) {
        return;
      }
      //
      this.usersStore.User = jsData;
      this.data = this.usersStore.User.rDomainBF;
      //
    }).catch((err) => {
      console.log(err);
      // This code runs if there were any errors.
      this.$router.replace("/client/domain-contact-details-bf");
    });
    //
    //
    this.__update();
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        waitLoader.hide();
      }, 1);
    },

    update() {
      setTimeout(() => {
        //
        this.domainName = this.usersStore.User.activateDomain;
      }, 1000);

      //check if enterprise id exist
      let h = this.usersStore.User.data.enterprise_id;
      this.hasEnterprise = h == null ? false : true;
      //
      //
      this.getUserData();
    },

    async getUserData() {
      try {
        //get All user data in json format
        let id = this.usersStore.User.account.id;
        id = functions.correctQueryString(id);
        const resp = await axios.get(Globals.website_url + "php/api.php?query=get-user-data-by-id/" + id);
        this.userData = resp.data;
        //refresh the header userName
        $("#headerUserName")[0].innerHTML = this.userData.fname;
        //
        //
        this.usersStore.User.data = this.userData;
        //
        waitLoader.hide();
      } catch (err) {
        // Handle Error Here
        waitLoader.hide();
        //
        //say error
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Erreur de récupération des données. Veuillez actualiser la page"
        );
        popup.setButton("Ok");
        popup.show();
        //
        this.userData = {};
      }

    },


    isImg(file) {
      return file.type.indexOf("image/") >= 0 ? true : false;
    },


    getImg1Src(inputId) {
      const fileInput = document.getElementById(inputId);
      //
      if (fileInput.files && fileInput.files[0]) {
        let file = fileInput.files[0];
        this.doc1 = file;
        //
        //new file name for avoid uploading errors
        let newName = file.name.replaceAll("'", "_");
        let $self = this;
        //
        const reader1 = new FileReader();
        reader1.onload = function (e) {
          const newFile = new File([e.target.result], newName, { type: file.type });
          $self.doc1 = newFile;
        };
        reader1.readAsArrayBuffer(file);
        //
        //
        const reader = new FileReader();
        reader.onload = (e) => {
          this.img1Src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
      else {
        this.doc1 = null;
      }
    },


    getImg2Src(inputId) {
      const fileInput = document.getElementById(inputId);
      //
      if (fileInput.files && fileInput.files[0]) {
        let file = fileInput.files[0];
        this.doc2 = file;
        //
        //new file name for avoid uploading errors
        let newName = file.name.replaceAll("'", "_");
        let $self = this;
        //
        const reader1 = new FileReader();
        reader1.onload = function (e) {
          const newFile = new File([e.target.result], newName, { type: file.type });
          $self.doc2 = newFile;
        };
        reader1.readAsArrayBuffer(file);
        //
        //
        const reader = new FileReader();
        reader.onload = (e) => {
          this.img2Src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
      else {
        this.doc2 = null;
      }
    },


    getPdfForm(inputId) {
      const fileInput = document.getElementById(inputId);
      //
      if (fileInput.files && fileInput.files[0]) {
        let file = fileInput.files[0];
        this.pdfForm = file;
        //
        //new file name for avoid uploading errors
        let newName = file.name.replaceAll("'", "_");
        let $self = this;
        //
        const reader1 = new FileReader();
        reader1.onload = function (e) {
          const newFile = new File([e.target.result], newName, { type: file.type });
          $self.pdfForm = newFile;
        };
        reader1.readAsArrayBuffer(file);
      }
      else {
        this.pdfForm = null;
      }
    },




    validatePerson() {
      if (this.formIsBusy) {
        return;
      }
      //
      this.formIsBusy = true;
      waitLoader.show();
      //
      var subs_id = functions.correctQueryString(this.usersStore.User.activateDomainSubsId);
      var doc1Path = "";
      var pdfFormPath = "";
      //
      let formData = new FormData();
      formData.append("fileToUpload", this.pdfForm);
      formData.append("domain", this.data.Domain);
      formData.append("doc", "form");
      //
      axios.post(Globals.website_url + "php/uploadDoc.php", formData).then((response) => {
        let h = response.data;
        console.log(h);

        //on error
        if (h === "") {
          //
          waitLoader.hide();
          this.formIsBusy = false;
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème de téléchargement des documents!<br>Veuillez reéssayer.");
          popup.setButton("Ok");
          popup.show();
          return;
        }
        //on success
        pdfFormPath = response.data;
        //
        formData = new FormData();
        formData.append("fileToUpload", this.doc1);
        formData.append("domain", this.data.Domain);
        formData.append("doc", "doc");
        //
        axios.post(Globals.website_url + "php/uploadDoc.php", formData).then((response) => {
          h = response.data;
          console.log(h);
          
          //on error
          if (h === "") {
            //
            waitLoader.hide();
            this.formIsBusy = false;
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage("Problème de téléchargement de vos documents!<br>Veuillez reéssayer.");
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //on success
          doc1Path = response.data;
          //
          //save subscription docs in database
          let docsPath = JSON.stringify([doc1Path]);
          docsPath = functions.correctQueryString(docsPath);
          //
          const fd = new FormData();
          fd.append('query', 'set-subscription-docs/' + subs_id + "/" + functions.correctQueryString(pdfFormPath) + "/" + docsPath);
          //make the subscription
          axios.post(Globals.website_url + "php/api.php", fd).then((response) => {
            h = response.data;
            //on error
            if (h !== 1) {
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Erreur de soumission des informations.<br>Veuillez reéssayer."
              );
              popup.setButton("Ok");
              popup.show();
              //
              waitLoader.hide();
              this.formIsBusy = false;
              return;
            }
            //
            //on success
            waitLoader.hide();
            this.formIsBusy = false;
            //
            popup.action = "submission";
            popup.setTitle("Notification");
            popup.setMessage(
              "Informations soumises avec succès."
            );
            popup.setButton("Ok");
            popup.show();
            //
            $("#popupYes").click(() => {
              if (popup.action !== 'submission') {
                return
              }
              //
              this.$router.push("/client/Subscriptions/services");
            });
            //
          })
            .catch(() => {
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage("Impossible soumettre vos documents.");
              popup.setButton("Ok");
              popup.show();
              //
              waitLoader.hide();
              this.formIsBusy = false;
            });
          //
        })
          .catch(() => {
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage("Impossible de télécharger vos documents.");
            popup.setButton("Ok");
            popup.show();
            //
            waitLoader.hide();
            this.formIsBusy = false;
          });
        //
        //
      })
        .catch(() => {
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Impossible de télécharger vos documents.");
          popup.setButton("Ok");
          popup.show();
          //
          waitLoader.hide();
          this.formIsBusy = false;
        });
    },




    validateStruct() {
      if (this.formIsBusy) {
        return;
      }
      //
      this.formIsBusy = true;
      waitLoader.show();
      //
      var subs_id = functions.correctQueryString(this.usersStore.User.activateDomainSubsId);
      var doc1Path = "";
      var pdfFormPath = "";
      //
      let formData = new FormData();
      formData.append("fileToUpload", this.pdfForm);
      formData.append("domain", this.data.Domain);
      formData.append("doc", "form");
      //
      axios.post(Globals.website_url + "php/uploadDoc.php", formData).then((response) => {
        let h = response.data;
        //on error
        if (h === "") {
          //
          waitLoader.hide();
          this.formIsBusy = false;
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème de téléchargement des documents !<br>Veuillez reéssayer.");
          popup.setButton("Ok");
          popup.show();
          return;
        }
        //on success
        pdfFormPath = response.data;
        //
        formData = new FormData();
        formData.append("fileToUpload", this.doc1);
        formData.append("domain", this.data.Domain);
        formData.append("doc", "docX");
        //
        axios.post(Globals.website_url + "php/uploadDoc.php", formData).then((response) => {
          h = response.data;
          //on error
          if (h === "") {
            //
            waitLoader.hide();
            this.formIsBusy = false;
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage("Problème de téléchargement de vos documents !<br>Veuillez reéssayer.");
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //on success
          doc1Path = response.data;
          //
          //save subscription docs in database
          let docsPath = JSON.stringify([doc1Path]);
          docsPath = functions.correctQueryString(docsPath);
          //
          const fd = new FormData();
          fd.append('query', 'set-subscription-docs/' + subs_id + "/" + functions.correctQueryString(pdfFormPath) + "/" + docsPath);
          //make the subscription
          axios.post(Globals.website_url + "php/api.php", fd).then((response) => {
            h = response.data;
            //on error
            if (h !== 1) {
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Erreur de soumission des informations.<br>Veuillez reéssayer."
              );
              popup.setButton("Ok");
              popup.show();
              //
              waitLoader.hide();
              this.formIsBusy = false;
              return;
            }
            //
            //on success
            waitLoader.hide();
            this.formIsBusy = false;
            //
            popup.action = "submission";
            popup.setTitle("Notification");
            popup.setMessage(
              "Informations soumises avec succès."
            );
            popup.setButton("Ok");
            popup.show();
            //
            $("#popupYes").click(() => {
              if (popup.action !== 'submission') {
                return
              }
              //
              this.$router.push("/client/Subscriptions/services");
            });
            //
          })
            .catch(() => {
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage("Impossible soumettre vos documents.");
              popup.setButton("Ok");
              popup.show();
              //
              waitLoader.hide();
              this.formIsBusy = false;
            });
          //
        })
          .catch(() => {
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage("Impossible de télécharger vos documents.");
            popup.setButton("Ok");
            popup.show();
            //
            waitLoader.hide();
            this.formIsBusy = false;
          });
        //
        //
      })
        .catch(() => {
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Impossible de télécharger vos documents.");
          popup.setButton("Ok");
          popup.show();
          //
          waitLoader.hide();
          this.formIsBusy = false;
        });
    },




    validatePrest() {
      if (this.formIsBusy) {
        return;
      }
      //
      this.formIsBusy = true;
      waitLoader.show();
      //
      var subs_id = functions.correctQueryString(this.usersStore.User.activateDomainSubsId);
      var doc1Path = "";
      var doc2Path = "";
      var pdfFormPath = "";
      //
      let formData = new FormData();
      formData.append("fileToUpload", this.pdfForm);
      formData.append("domain", this.data.Domain);
      formData.append("doc", "form");
      //
      axios.post(Globals.website_url + "php/uploadDoc.php", formData).then((response) => {
        let h = response.data;
        //on error
        if (h === "") {
          //
          waitLoader.hide();
          this.formIsBusy = false;
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème de téléchargement des documents !<br>Veuillez reéssayer.");
          popup.setButton("Ok");
          popup.show();
          return;
        }
        //on success
        pdfFormPath = response.data;
        //
        formData = new FormData();
        formData.append("fileToUpload", this.doc1);
        formData.append("domain", this.data.Domain);
        formData.append("doc", "doc1");
        //
        axios.post(Globals.website_url + "php/uploadDoc.php", formData).then((response) => {
          let h = response.data;
          //on error
          if (h === "") {
            //
            waitLoader.hide();
            this.formIsBusy = false;
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage("Problème de téléchargement des documents !<br>Veuillez reéssayer.");
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //on success
          doc1Path = response.data;
          //
          formData = new FormData();
          formData.append("fileToUpload", this.doc2);
          formData.append("domain", this.data.Domain);
          formData.append("doc", "doc2");
          //
          axios.post(Globals.website_url + "php/uploadDoc.php", formData).then((response) => {
            h = response.data;
            //on error
            if (h === "") {
              //
              waitLoader.hide();
              this.formIsBusy = false;
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage("Problème de téléchargement de vos documents !<br>Veuillez reéssayer.");
              popup.setButton("Ok");
              popup.show();
              return;
            }
            //on success
            doc2Path = response.data;
            //
            //save subscription docs in database
            let docsPath = JSON.stringify([doc1Path, doc2Path]);
            docsPath = functions.correctQueryString(docsPath);
            //
            const fd = new FormData();
            fd.append('query', 'set-subscription-docs/' + subs_id + "/" + functions.correctQueryString(pdfFormPath) + "/" + docsPath);
            //make the subscription
            axios.post(Globals.website_url + "php/api.php", fd).then((response) => {
              h = response.data;
              //on error
              if (h !== 1) {
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage(
                  "Erreur de soumission des informations.<br>Veuillez reéssayer."
                );
                popup.setButton("Ok");
                popup.show();
                //
                waitLoader.hide();
                this.formIsBusy = false;
                return;
              }
              //
              //on success
              waitLoader.hide();
              this.formIsBusy = false;
              //
              popup.action = "submission";
              popup.setTitle("Notification");
              popup.setMessage(
                "Informations soumises avec succès."
              );
              popup.setButton("Ok");
              popup.show();
              //
              $("#popupYes").click(() => {
                if (popup.action !== 'submission') {
                  return
                }
                //
                this.$router.push("/client/Subscriptions/services");
              });
            })
              .catch(() => {
                //say error
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage("Impossible soumettre vos documents.");
                popup.setButton("Ok");
                popup.show();
                //
                waitLoader.hide();
                this.formIsBusy = false;
              });
            //
          })
            .catch(() => {
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage("Impossible de télécharger vos documents.");
              popup.setButton("Ok");
              popup.show();
              //
              waitLoader.hide();
              this.formIsBusy = false;
            });
          //
          //
        })
          .catch(() => {
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage("Impossible de télécharger vos documents.");
            popup.setButton("Ok");
            popup.show();
            //
            waitLoader.hide();
            this.formIsBusy = false;
          });
      })
        .catch(() => {
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Impossible de télécharger vos documents.");
          popup.setButton("Ok");
          popup.show();
          //
          waitLoader.hide();
          this.formIsBusy = false;
        });
    }
    //
    //
  }
};
</script>

<style scoped>
@import 'intl-tel-input/build/css/intlTelInput.css';

#rDomain {
  padding: 12px 12px 24px;
  position: relative;
}


#downloadPDF {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 6px 12px;
  border-radius: 50px;
  background-color: white;
  cursor: default;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
}

#downloadPDF i {
  font-size: 24px;
}

.myBtn {
  margin-right: 32px;
  margin-top: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 50%;
  padding: 6px 12px;
  background-color: white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.myBtn:hover {
  background-color: rgb(199, 46, 46);
}

.myBtn i {
  font-size: 24px;
  color: black;
}

.myBtn:hover i {
  color: white;
}



/*----------------------------------
USER DATA EDIT
----------------------------------*/
#rDomain form {
  max-width: 640px;
  background-color: #F2FBFF;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  text-align: left;
  color: #111a2b;
  padding: 28px;
}

#rDomain form input[readonly] {
  background-color: #f8f8f8;
  color: #888;
}

#rDomain form select {
  /**/
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  background-color: white;
}

#rDomain form button[type=submit] {
  margin-left: 12px;
  border: none;
  border-radius: 3px;
  padding: 8px 24px;
  width: auto;
  box-sizing: content-box;
  color: white;
  background-color: #111a2b;
  font-size: 17px;
}

#rDomain form button[type=submit]:hover {
  color: white;
  background-color: #1b2842;
}

#rDomain form button[type=submit] i {
  color: rgba(123, 255, 0);
  font-size: 16px;
}

#rDomain form button[type=submit]:hover {
  background-color: #346;
}

.rDomaintitle {
  border-bottom: solid 1px black;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.rDomaintitle button {
  float: left !important;
}




#process {
  margin: 8px 0;
  background-color: rgb(255, 230, 230);
  color: rgb(199, 46, 46);
  padding: 8px 16px;
  border-radius: 6px;
  width: 768px;
}



.noPreview {
  height: 112px !important;
  align-items: center !important;
}

.noPrev {
  height: max-content !important;
}
</style>