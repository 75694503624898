<template>
  <div id="bg">
    <form id="emailConfirmed" @submit.prevent="validate()" class="text-center">
      <img src="@/assets/images/logo-ecodev.png" />

      <br />
      <i class="bi bi-x-octagon-fill"></i>

      <h4>
        La campagne de promotion pour les noms de domaine .bf est terminée.
        <br /><br />
        Pour enregistrer un nom de domaine .bf, vous devez désormais payer le
        tarif standart de 14407 FCFA HT.
        <br /><br />
        Pour se faire, veuillez créér votre compte en cliquant sur le button
        ci-dessous
      </h4>
      <br />

      <div class="w-100 d-flex justify-content-center">
        <button type="submit" class="btn p-3 fs-5">Créér mon compte</button>
      </div>
    </form>
  </div>
</template>

<script>
import globals from "@/common/js/Globals";

export default {
  methods: {
    validate() {
      location.replace(globals.website_url + "?signup=1#/login");
      return false;
    },
  },
};
</script>

<style scoped>
#bg {
  background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

form {
  color: black;
  max-width: 680px;
  width: 100%;
  background-color: #f2fbff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  float: left;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  padding: 112px;
}

form img {
  width: 260px;
  margin-bottom: 32px;
}

form h3 {
  margin-bottom: 24px;
}

form input {
  border: solid 1px #ddd;
  padding: 12px 12px;
}

form i {
  font-size: 80px;
  color: rgb(190, 23, 23);
}

form button[type="submit"] {
  margin-left: 12px;
  margin-top: 0;
  border: none;
  box-sizing: content-box;
  color: white;
  background-color: #ff6600;
  font-size: 17px;
}

form button[type="submit"]:hover {
  background-color: #da5700;
}

/**/
@media only screen and (max-width: 480px) {
  form {
    box-shadow: none;
    border: solid 1px #ccc;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}

@media only screen and (max-height: 512px) {
  form {
    top: 0;
    transform: translateY(0) translateX(-50%);
  }
}
</style>
