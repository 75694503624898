<template>
  <main>
    <br />
    <div id="confirmOrder">
      <div class="confirmOrderBox">
        <br />

        <h4 class="confirmOrderTitle">Details de {{ operation }}</h4>
        <div class="confirmOrderItems">
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">Service commandé</b>
            <a class="col-sm-8 col-12">{{
              service.type + " " + service.label
            }}</a>
          </div>
          <div
            class="row confirmOrderItem"
            v-if="domain != null && domain != ''"
          >
            <b class="col-sm-4 col-12">Nom de Domaine</b>
            <a class="col-sm-8 col-12">{{ domain }}</a>
          </div>
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">Cycle de facturation</b>
            <span class="col-sm-3">
              <select
                id="chooseOfferModality"
                v-model="offerModality"
                class="px-1"
                @change="
                  nbPayment = offerModalityCount[offerModality][0];
                  calculatePrice();
                "
              >
                <option
                  value="Y"
                  v-if="service.standart == 1 || service.cycle_period == 0"
                >
                  Annuel
                </option>
                <option
                  value="M"
                  v-if="
                    service.standart == 0 &&
                    (service.cycle_period == 0 || service.price_period == 'M')
                  "
                >
                  Mensuel
                </option>
              </select>
              <span class="infos ms-2">
                <i class="bi bi-info bg-primary text-white me-1 fs-6"></i>
                <div class="tooltips text-dark bg-white p-2 border-primary">
                  <span>
                    Pour passer du cycle de facturation mensuelle au cycle de
                    facturation annuelle il faut couvrir 12 mois de paiement. Et
                    au 12ème mois, pas plus, pas moins, le cycle de factuation
                    annuelle sera disponible.
                  </span>
                </div>
              </span>
            </span>
          </div>

          <div class="space confirmOrderItem"></div>

          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12"
              >Nombre {{ offerModality == Y ? "d' " : "de "
              }}{{ offerModalityText[offerModality] }}
            </b>
            <select
              id="nbPayment"
              class="px-1 col-sm-3"
              v-model="nbPayment"
              @change="calculatePrice()"
            >
              <option
                v-for="cnt in offerModalityCount[offerModality]"
                :value="cnt"
              >
                {{ cnt }} {{ offerModalityText[offerModality] }}
              </option>
            </select>
          </div>
          <!--  -->
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">Prix de réservation HT</b>
            <a class="col-sm-8 col-12">{{ correctPrice(price_ht) }} FCFA</a>
          </div>
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">TVA ({{ service.tva_percent }}%)</b>
            <a class="col-sm-8 col-12"
              >{{ correctPrice(service.price_tva) }} FCFA</a
            >
          </div>
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">Prix de réservation TTC</b>
            <a class="col-sm-8 col-12"
              >{{ correctPrice(service.price_ttc) }} FCFA</a
            >
          </div>
          <!--  -->
          <div class="promoBox">
            <div class="row">
              <b class="col-sm-4 mt-1">Code promo (facultatif)</b>
              <input
                id="codePromo"
                class="px-1 col-sm-3"
                v-model="codePromo"
                placeholder="Entrez le code ici"
                @input="resetPromo()"
              />
              <button
                class="btn btn-sm btn-primary col-sm-1"
                type="button"
                @click="applyPromo()"
              >
                Valider
              </button>
            </div>
            <div class="row confirmOrderItem2">
              <b class="col-sm-4 col-12">Réduction</b>
              <a v-if="promo.type_remise == 'COST'" class="col-sm-8 col-12"
                >- {{ correctPrice(promo.amount) }} FCFA</a
              >
              <a v-else class="col-sm-8 col-12">- {{ promo.amount }} %</a>
            </div>
            <div class="row confirmOrderItem2">
              <b class="col-sm-4 col-12">Prix Final TTC</b>
              <a class="col-sm-8 col-12"
                >{{ correctPrice(service.final_price_ttc) }} FCFA</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="confirmOrderBox">
        <h4 class="confirmOrderTitle">Mode de paiement</h4>
        <div class="row">
          <div class="col-sm-5 col-12 mb-3 paymod">
            <div class="row paymodbtn" ids="1" @click="payModeClick">
              <div class="col d-flex align-items-center">Paiement mobile</div>
              <div
                class="col-3 d-flex justify-content-center align-items-center"
              >
                <i class="bi bi-phone fs-1"></i>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="col-sm-5 col-12 mb-3 paymod">
            <div class="row paymodbtn" ids="2" @click="payModeClick">
              <div class="col d-flex align-items-center">Carte de credit</div>
              <div
                class="col-3 d-flex justify-content-center align-items-center"
              >
                <i class="bi bi-credit-card-2-back fs-1"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5 col-12 mb-3 paymod">
            <div class="row paymodbtn" ids="3" @click="payModeClick">
              <div class="col d-flex align-items-center">
                Paiement par Chèque
              </div>
              <div
                class="col-3 d-flex justify-content-center align-items-center"
              >
                <i class="bi bi-card-heading fs-1"></i>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="col-sm-5 col-12 mb-3 paymod">
            <div class="row paymodbtn" ids="4" @click="payModeClick">
              <div class="col d-flex align-items-center">
                Paiement par Virement
              </div>
              <div
                class="col-3 d-flex justify-content-center align-items-center"
              >
                <i class="bi bi-bank fs-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="confirmOrderBox" v-if="service.contract">
        <h4 class="confirmOrderTitle fs-6">
          Conditions Générales de Vente (CGV) / Conditions d'utilisation -
          ECODEV
        </h4>
        <textarea
          id="sellerContract"
          :value="service.contract"
          readonly
          tabindex="-1"
        >
        </textarea>

        <input
          id="confirmOrderChecker"
          type="checkbox"
          v-model="confirmOrder"
        />
        <label for="confirmOrderChecker" class="ms-1">
          J'ai lu et j'accepte les conditions générales de vente.</label
        >
      </div>

      <div
        id="cfoBtns"
        class="btn-group btn-group-toggle"
        data-toggle="buttons"
      >
        <button
          v-on:click="back()"
          class="confirmOrderItemBackBtn btn btn-secondary"
        >
          <i class="bi bi-arrow-left"></i>
        </button>
        <button
          v-on:click="validate()"
          class="confirmOrderItemValidateBtn btn btn-warning"
        >
          Confirmer la commande <i class="bi bi-caret-right-fill"></i>
        </button>
      </div>

      <br />
      <br />
    </div>
  </main>
</template>

<script>
const $ = require("jquery");
import functions from "/src/common/js/functions";
import Globals from "/src/common/js/Globals";
import axios from "axios";
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
//
import localforage from "localforage";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";

let currentDate = new Date();
let annee = currentDate.getFullYear();
let mois = currentDate.getMonth() + 1;
let jour = currentDate.getDate();
let heure = currentDate.getHours();
let minute = currentDate.getMinutes();
let seconde = currentDate.getSeconds();
let transaction_id = "" + annee + mois + jour + heure + minute + seconde;

//confirmOrder component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      pageName: "Confirmation du service",
      //
      subsExists: false,
      //
      operation: "souscription",
      operationType: 0,
      user: {},
      service: {},
      offer: {},
      serviceId: -1,
      offerId: -1,

      offerModality: "Y",
      offerModalityText: { Y: "Année(s)", M: "Mois" },
      offerModalityCount: {
        Y: [1, 2, 3, 4, 5],
        M: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      },

      confirmOrder: false,
      payModeId: -1,
      payNumber: "",
      payModes: Array(),
      domain: "",
      hasDomain: false,
      //
      selectPayMethod: "",
      currency: "XOF",
      site_id: "5869953",
      channels: "CREDIT_CARD",
      transaction_id: transaction_id,
      zip_code: "3215",
      //
      codePromo: "",
      promo: {
        amount: 0,
      },
      //
      nbPayment: 1,
    };
  },

  mounted() {
    waitLoader.show();
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    if (this.service && this.service.contract)
      document.getElementById("confirmOrderChecker").checked = false;
    this.confirmOrder = false;
    //
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    payModeClick(ev) {
      let obj = ev.currentTarget;
      //
      $(".paymodbtn").attr("selected", "false");
      setTimeout(() => {
        obj.setAttribute("selected", "true");
      }, 5);
      //
      this.selectPayMethod = obj.getAttribute("ids");
    },

    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
      }, 1);
    },

    async update() {
      waitLoader.show();
      //
      this.operationType = this.usersStore.User.account.payOperationType;

      this.user = this.usersStore.User.data;
      this.hasDomain = this.usersStore.User.account.hasDomain;
      if (this.hasDomain == 1 || this.hasDomain == 2)
        this.domain = this.usersStore.User.account.curOfferDomain;
      else this.domain = null;

      try {
        switch (this.operationType) {
          case 0: {
            //on domain subscription
            this.operation = "souscription";
            //
            this.subsExists =
              this.usersStore.User.account.curSubscriptionIds != null
                ? true
                : false;
            //
            this.serviceId = this.usersStore.User.account.curServiceId;
            let eid = functions.correctQueryString(
              "" + this.usersStore.User.data.enterprise_id
            );
            this.serviceId = functions.correctQueryString(
              "" + this.usersStore.User.account.curServiceId
            );
            let resp = await axios.get(
              Globals.website_url +
                "php/api.php?query=get-service-by-id/" +
                eid +
                "/" +
                this.serviceId
            );
            this.service = resp.data;
            //
            this.service.price_period = "Y";
            this.service.cycle_period = 0;
            //
            break;
          }

          case 1: {
            //on domain renewal
            this.operation = "renouvellement";
            //
            this.domain_price =
              this.usersStore.User.account.curOfferDomainPrice;
            //
            this.subsExists =
              this.usersStore.User.account.curSubscriptionIds != null
                ? true
                : false;
            //
            //
            this.serviceId = this.usersStore.User.account.curServiceId;
            let eid = functions.correctQueryString(
              this.usersStore.User.data.enterprise_id + ""
            );
            this.serviceId = functions.correctQueryString(
              "" + this.usersStore.User.account.curServiceId
            );
            let resp = await axios.get(
              Globals.website_url +
                "php/api.php?query=get-service-by-id/" +
                eid +
                "/" +
                this.serviceId
            );
            this.service = resp.data;
            //
            break;
          }

          case 2: {
            //on offer subscription
            this.operation = "souscription";
            //
            this.subsExists =
              this.usersStore.User.account.curSubscriptionIds != null
                ? true
                : false;
            //
            let eid = functions.correctQueryString(
              this.usersStore.User.data.enterprise_id + ""
            );
            this.serviceId = functions.correctQueryString(
              "" + this.usersStore.User.account.curServiceId
            );
            let resp = await axios.get(
              Globals.website_url +
                "php/api.php?query=get-service-by-id/" +
                eid +
                "/" +
                this.serviceId
            );
            this.service = resp.data;
            //
            const createArray = function (n) {
              let arr = [];
              while (12 - n > 0) {
                arr.push(n);
                n++;
              }
              return arr;
            };
            this.offerModalityCount["M"] = createArray(
              this.service.nb_payment_initial
            );
            //
            let userId = this.usersStore.User.account.id;
            userId = functions.correctQueryString(userId);
            //
            if (this.subsExists) {
              let resp = await axios.get(
                Globals.website_url +
                  "php/api.php?query=get-user-subscription-by-id/" +
                  userId +
                  "/" +
                  this.usersStore.User.account.curSubscriptionIds
              );
              //
              this.service.tva_percent = resp.data.tva_percent;
            }
            this.service.price_period = "Y";
            this.service.cycle_period = 0;
            //
            break;
          }

          case 3: {
            //on offer renewal
            this.operation = "renouvellement";
            //
            this.subsExists =
              this.usersStore.User.account.curSubscriptionIds != null
                ? true
                : false;
            //
            let eid = functions.correctQueryString(
              this.usersStore.User.data.enterprise_id + ""
            );
            this.serviceId = functions.correctQueryString(
              "" + this.usersStore.User.account.curServiceId
            );
            let resp = await axios.get(
              Globals.website_url +
                "php/api.php?query=get-service-by-id/" +
                eid +
                "/" +
                this.serviceId
            );

            this.service = resp.data;
            //
            let userId = this.usersStore.User.account.id;
            userId = functions.correctQueryString(userId);
            if (this.subsExists) {
              let resp = await axios.get(
                Globals.website_url +
                  "php/api.php?query=get-user-subscription-by-id/" +
                  userId +
                  "/" +
                  this.usersStore.User.account.curSubscriptionIds
              );
              //
              this.service.tva_percent = resp.data.tva_percent;
              this.service.price_period = resp.data.price_period;
              this.service.cycle_period = resp.data.cycle_period;
            }
            break;
          }
        }
        //
        //
        if (
          this.service.standart == 1 ||
          (this.service.standart == 0 &&
            this.service.price_period == "M" &&
            this.service.cycle_period != 0)
        ) {
          this.offerModality = "M";
        }
        //
        setTimeout(() => {
          if (
            document.querySelectorAll("#chooseOfferModality option").length ===
              1 &&
            $("#chooseOfferModality option")[0].value !== "M"
          ) {
            this.offerModality = "Y";
          }
          //
          this.calculatePrice();
        }, 150);

        //
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
      //
      //get offerId
      this.offerId = this.usersStore.User.account.curOfferId;
      //
      waitLoader.hide();
    },

    calculatePrice() {
      if (this.operationType == 0 || this.operationType == 1) {
        this.domain_price = this.usersStore.User.account.curOfferDomainPrice;
      } else {
        switch (this.offerModality) {
          case "M":
            this.domain_price = this.service.price_per_month;
            break;
          default:
            this.domain_price = this.service.price_per_year;
        }
      }

      //all prices
      let price = this.domain_price * this.nbPayment;
      this.price_ht = price;
      //tva price
      this.service.price_tva = price * (this.service.tva_percent / 100);
      this.service.price_tva = Math.ceil(this.service.price_tva * 100) / 100;
      //
      this.service.price_ttc = price * (1 + this.service.tva_percent / 100);
      this.service.price_ttc = Math.ceil(this.service.price_ttc / 5) * 5;
      this.service.final_price_ttc = this.service.price_ttc;
    },

    correctPrice(price) {
      return functions.correctPrice(price);
    },

    setConfirmOrder() {
      this.comfirmOrder = this.offerModality;
    },

    back() {
      this.$router.back();
    },

    resetPromo() {
      this.promo = { amount: 0 };
      this.service.final_price_ttc = this.service.price_ttc;
    },

    applyPromo() {
      waitLoader.show();
      //
      let code = functions.correctQueryString("" + this.codePromo);
      let price = functions.correctQueryString("" + this.domain_price);
      let user_id = functions.correctQueryString(
        "" + this.usersStore.User.data.id
      );
      //
      axios
        .post(
          Globals.website_url +
            "php/api.php?query=get-promo-by-code/" +
            code +
            "/" +
            price +
            "/" +
            user_id
        )
        .then((response) => {
          let h = response.data;
          // console.log(h);
          //on error
          if (!h || h.code == null || typeof h == "string") {
            this.resetPromo();
            //
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage("Code promo invalide.");
            popup.setButton("Ok");
            popup.show();
            //
            waitLoader.hide();
            return;
          }
          waitLoader.hide();
          //on success
          this.promo = h;
          //calculate the new final price
          if (this.promo.type_remise == "COST") {
            let pht = this.domain_price - this.promo.amount;
            this.service.final_price_ttc =
              pht * (1 + this.service.tva_percent / 100);
            this.service.final_price_ttc =
              Math.ceil(this.service.final_price_ttc / 5) * 5;
            this.service.final_price_ttc = Math.max(
              this.service.final_price_ttc,
              0
            );
          } else {
            this.service.final_price_ttc =
              this.service.price_ttc -
              (this.service.price_ttc * this.promo.amount) / 100;
            this.service.final_price_ttc =
              Math.ceil(this.service.final_price_ttc / 5) * 5;
            this.service.final_price_ttc = Math.max(
              this.service.final_price_ttc,
              0
            );
          }
        })
        .catch(() => {
          waitLoader.hide();
        });
    },

    validate() {
      //no payment mode selected
      if (this.selectPayMethod === "") {
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage("Veuillez choisir un mode de paiement.");
        popup.setButton("Ok");
        popup.show();
        waitLoader.hide();
        return;
      }
      //selling policy not accepted
      if (this.service && this.service.contract && !this.confirmOrder) {
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Veuillez lire et accepter les conditions de vente avant de poursuivre."
        );
        popup.setButton("Ok");
        popup.show();
        return;
      }

      //check if subscription exists
      if (this.subsExists) {
        this.pay();
        return;
      }

      waitLoader.show();

      //on good infos store data into session data
      this.usersStore.User.account.curConfirmOrder = this.confirmOrder;
      //create the subscription
      let id = this.user.id;
      let serviceId = "" + this.usersStore.User.account.curServiceId;
      let offerId = "Y";
      let offerModality = 1;
      let domain = this.domain;
      //
      id = functions.correctQueryString(id + "");
      offerId = functions.correctQueryString(offerId + "");
      offerModality = functions.correctQueryString(offerModality + "");
      //payModeId = functions.correctQueryString(payModeId + "");
      domain = functions.correctQueryString(domain);
      //
      //make the subscription
      axios
        .post(
          Globals.website_url +
            "php/api.php?query=subscribe-offer-domain/" +
            id +
            "/" +
            serviceId +
            "/" +
            offerId +
            "/" +
            offerModality +
            "/" +
            domain +
            "/" +
            this.domain_price +
            "/" +
            this.usersStore.User.account.curOfferDomainReab
        )
        .then((response) => {
          var h = response.data;
          //on error
          if (h == null || h.length >= 4) {
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Erreur de l'enregistrement de la souscription.<br>Veuillez reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
            //
            waitLoader.hide();
            return;
          }
          waitLoader.hide();
          //on success
          this.offer = h;
          this.usersStore.User.account.curSubscriptionIds = h.id;
          //

          //
          //FB CONFIRM DOMAIN FOR PAY DOMAIN
          window.fbq("trackCustom", "InitiateOrderDomain", {
            user: {
              fullname: this.user.fname,
              email: this.user.email,
              country: this.user.country,
            },
            offer_type: this.service.type,
            offer_name: this.service.label,
            domain: this.domain,
            price: this.service.price_ttc,
          });

          //Google Analytics
          this.$gtag.event("InitiateOrderDomain", {
            user: {
              fullname: this.user.fname,
              email: this.user.email,
              country: this.user.country,
            },
            offer_type: this.service.type,
            offer_name: this.service.label,
            domain: this.domain,
            price: this.service.price_ttc,
          });
          //
          popup.action = "subscription ok";
          popup.setTitle("Notification");
          popup.setMessage(
            "Souscription enregistrée. Voulez vous effectuer le paiment ?"
          );
          popup.setButton("Oui", "Non");
          popup.show();
          //
          $("#popupYes").click(() => {
            //
            if (popup.action == "subscription ok") this.pay();
          });

          $("#popupNo").click(() => {
            //
            if (popup.action == "subscription ok")
              this.$router.push("/client/Subscriptions/Services");
          });

          $("#popupCloseBtn").click(() => {
            //
            if (popup.action == "subscription ok")
              this.$router.push("/client/Subscriptions/Services");
          });
        })
        .catch(() => {
          waitLoader.hide();
        });
    },

    pay() {
      if (this.service && this.service.contract && !this.confirmOrder) {
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Veuillez lire et accepter les conditions de vente avant de poursuivre."
        );
        popup.setButton("Ok");
        popup.show();
        return;
      }
      //
      waitLoader.show();
      //on renewal
      if (this.operationType == 1 || this.operationType == 3) {
        this.payMethod();
        return;
      }
      //on subscription
      axios
        .post(
          Globals.website_url +
            "php/api.php?query=check-if-subscription-domain-is-free/" +
            this.domain
        )
        .then(async (response) => {
          let h = response.data;

          //on error
          if (h != "1") {
            waitLoader.hide();
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Ce nom de domaine n'est plus disponible!<br>Vous pouvez supprimer cet enregistrement."
            );
            popup.setButton("Ok");
            popup.show();
            //
            return;
          }
          //on free domain
          this.payMethod();
        })
        .catch((e) => {
          console.log(e);

          waitLoader.hide();
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème de vérification sur le nom de domaine.");
          popup.setButton("Ok");
          popup.show();
        });
    },

    async chequeValidate() {
      try {
        this.usersStore.User.account.curTva = this.service.tva_percent;
        this.usersStore.User.account.amount = this.service.final_price_ttc;
        this.usersStore.User.account.nbPayment = this.nbPayment;
        this.usersStore.User.account.periodPrice = this.service.price_period;
        //
        await localforage.setItem(
          "ecocloud/User",
          JSON.stringify(this.usersStore.User)
        );
        //
        this.$router.push("/client/chequePay");
      } catch (err) {
        // This code runs if there were any errors.
        console.log(err);
      }
    },

    async transferValidate() {
      try {
        this.usersStore.User.account.curTva = this.service.tva_percent;
        this.usersStore.User.account.amount = this.service.final_price_ttc;
        this.usersStore.User.account.nbPayment = this.nbPayment;
        this.usersStore.User.account.periodPrice = this.service.price_period;
        //
        await localforage.setItem(
          "ecocloud/User",
          JSON.stringify(this.usersStore.User)
        );
        //
        this.$router.push("/client/transfertPay");
      } catch (err) {
        // This code runs if there were any errors.
        console.log(err);
      }
    },

    payMethod() {
      switch (this.selectPayMethod) {
        case "1":
          this.mobileMoney();
          break;
        case "2":
          this.cardValidate();
          break;
        case "3":
          this.chequeValidate();
          break;
        case "4":
          this.transferValidate();
          break;
        default:
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Veuillez choisir un mode de paiement.");
          popup.setButton("Ok");
          popup.show();
          waitLoader.hide();
      }
    },

    mobileMoney() {
      let returnUrl = Globals.website_url + "#/client/Subscriptions/services";

      // let ext = this.domain.substring(this.domain.lastIndexOf(".") + 1);
      //
      var params = {
        items: [
          {
            description: this.service.type + " " + this.service.label,
            amount: this.service.final_price_ttc,
            quantity: 1,
          },
        ],
        returnURL: returnUrl,
        service_code: "700",
        plugin_type: "std_data",
      };

      axios
        .post("https://gateway.upay-bf.com:3005/v1", params)
        .then((res) => {
          //
          waitLoader.hide();
          //on success
          if (res.data.success) {
            //
            let rid = res.data.requestId;
            let uid = this.usersStore.User.account.id;
            let oid = this.service.id;
            let sid = this.usersStore.User.account.curSubscriptionIds;
            //
            rid = functions.correctQueryString(rid + "");
            uid = functions.correctQueryString(uid + "");
            sid = functions.correctQueryString(sid + "");
            oid = functions.correctQueryString(oid + "");
            let spp = functions.correctQueryString(this.offerModality);
            let npp = functions.correctQueryString(this.nbPayment + "");
            let tva_percent = functions.correctQueryString(
              this.service.tva_percent + ""
            );
            let code = this.promo.type_remise ? this.codePromo : "";
            code = functions.correctQueryString(code);
            let amount = functions.correctQueryString(this.price_ht + "");
            let nbPayment = functions.correctQueryString(this.nbPayment + "");
            //
            axios
              .post(
                Globals.website_url +
                  "php/api.php?query=set-service-payment-data/" +
                  rid +
                  "/" +
                  uid +
                  "/" +
                  oid +
                  "/" +
                  sid +
                  "/" +
                  spp +
                  "/" +
                  npp +
                  "/" +
                  tva_percent +
                  "/" +
                  code +
                  "/" +
                  amount +
                  "/" +
                  nbPayment
              )
              .then(async (response) => {
                let h = response.data;

                console.log(h);
                //on error
                if (h != "1") {
                  waitLoader.hide();
                  //say error
                  popup.action = "notification";
                  popup.setTitle("Notification");
                  popup.setMessage("Problème d'enregistrement des données.");
                  popup.setButton("Ok");
                  popup.show();
                  //
                  return;
                }
                //
                //
                //on succes
                this.usersStore.User.activateDomain = this.domain;
                //save cache data
                try {
                  await localforage.setItem(
                    "ecocloud/User",
                    JSON.stringify(this.usersStore.User)
                  );
                } catch (err) {
                  // This code runs if there were any errors.
                }
                //
                location.replace(res.data.response_code);
              })
              .catch(() => {
                waitLoader.hide();
                //say error
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage(
                  "Problème de connexion!<br>Veuillez reéssayer."
                );
                popup.setButton("Ok");
                popup.show();
              });
          }
        })
        .catch(() => {
          //
          waitLoader.hide();
          // Handle error...
          popup.action = "Notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème de validation du paiement.");
          popup.setButton("Ok");
          popup.show();
        });
    },

    async cardValidate() {
      waitLoader.show();

      let user = this.usersStore.User;

      var dbData = new FormData();
      dbData.append("user_id", this.usersStore.User.data.id);
      dbData.append("subs_id", this.usersStore.User.account.curSubscriptionIds);
      dbData.append("transaction_id", "ECODEV_" + this.transaction_id);
      dbData.append("currency", this.currency);
      dbData.append("amount", this.service.final_price_ttc);
      dbData.append(
        "customer_name",
        user.data.fname.substring(user.data.fname.indexOf(" ") + 1)
      );
      dbData.append(
        "customer_surname",
        user.data.fname.substring(0, user.data.fname.indexOf(" "))
      );
      dbData.append("customer_id", user.data.id);
      dbData.append("customer_email", user.data.email);
      dbData.append("customer_phone_number", user.data.tel);
      dbData.append("customer_city", "Abidjan");
      dbData.append("customer_country", "CI");
      dbData.append("customer_state", "CI");
      dbData.append("customer_address", "Abidjan");
      dbData.append("customer_zip_code", "00225");
      dbData.append("price_period", this.offerModality);
      dbData.append("nbYears", this.nbPayment);
      //
      dbData.append("description", this.domain);
      //
      dbData.append("offer_id", user.account.curServiceId);
      dbData.append("tva", this.service.tva_percent);
      dbData.append("promo", this.promo.type_remise ? this.codePromo : "");
      try {
        const response = await fetch(
          Globals.website_url + "php/payment-api/cardSubscriptionInfoPay.php",
          {
            method: "POST",
            body: dbData,
          }
        );
        //
        let data = await response.text();

        console.log(data);

        if (data != 1) {
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème d'enregistrement des données.");
          popup.setButton("Ok");
          popup.show();
          waitLoader.hide();
          return false;
        }
      } catch (err) {
        //say error
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Veuillez vérifier votre connexion à internet puis reéssayer."
        );
        popup.setButton("Ok");
        popup.show();
        waitLoader.hide();
        return;
      }

      var formData = new FormData();
      formData.append("transaction_id", "ECODEV_" + this.transaction_id);
      formData.append("currency", this.currency);
      //
      formData.append("notify_url", Globals.cinet_notify_url);
      formData.append("return_url", Globals.cinet_return_url);
      formData.append("apikey", Globals.cinet_apikey);
      //
      formData.append("site_id", this.site_id);
      formData.append("amount", this.service.final_price_ttc);
      formData.append("channels", this.channels);
      formData.append(
        "customer_name",
        user.data.fname.substring(user.data.fname.indexOf(" ") + 1)
      );
      formData.append(
        "customer_surname",
        user.data.fname.substring(0, user.data.fname.indexOf(" "))
      );
      // dbData.append("customer_id", user.data.id);
      formData.append("customer_email", user.data.email);
      formData.append("customer_phone_number", user.data.tel);
      formData.append("customer_city", "Abidjan");
      formData.append("customer_country", "CI");
      formData.append("customer_state", "CI");
      formData.append("customer_address", "Abidjan");
      formData.append("customer_zip_code", "00225");
      formData.append("description", this.domain);

      fetch("https://api-checkout.cinetpay.com/v2/payment/", {
        method: "POST",
        body: formData,
      })
        .then(async function (response) {
          const resp = await response.json();
          // console.log(resp);
          let url = resp.data.payment_url;
          location = url;
        })
        .catch(function (error) {
          console.log(error);
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Veuillez vérifier votre connexion à internet puis reéssayer."
          );
          popup.setButton("Ok");
          popup.show();
          //
          waitLoader.hide();
        });
    },
  },
};
</script>

<style scoped>
/*-------------------------------
ConfirmOrder
-------------------------------*/
#confirmOrder {
  max-width: 840px;
  width: 100%;
  margin: auto;
  font-size: 16px;
}

.confirmOrderBox {
  width: 100%;
  margin: auto;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  padding: 28px;
  color: #111a2b;
  text-align: left;
  margin-bottom: 32px;
}

.infos {
  position: relative;
  top: 0px;
}

.infos:hover .tooltips {
  display: inline-block;
}

.infos .tooltips {
  display: none;
  position: absolute;
  left: 0;
  top: 22px;
  border-width: 1px;
  border-style: solid;
  width: 640px;
  max-width: calc(100vw - 16px) !important;
}

.infos i {
  border-radius: 50%;
  padding: 0 1.5px;
}
.infos span {
  font-style: italic;
}

.confirmOrderTitle {
  color: orange !important;
}

.confirmOrderItems {
  margin-top: 8px;
  width: 100%;
  height: max-content;
}

.confirmOrderItems {
  margin-bottom: 32px;
}

.confirmOrderItem:nth-child(odd) {
  background-color: white;
}

.space {
  height: 20px;
}

.confirmOrderItem a,
.confirmOrderItem2 a {
  color: black;
}

.confirmOrderItem p,
.confirmOrderItem2 p {
  overflow: auto;
  max-height: 128px;
}

#confirmOrderPayNumber {
  padding: 0 14px;
  height: 68px;
}

.confirmOrderItemBackBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}

.confirmOrderItemValidateBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}

@media only screen and (max-width: 316px) {
}

.paymod {
  background-color: transparent;
  margin: 0 8px;
}

.paymodbtn {
  display: flex;
  height: 80px;
  border: solid 1px #ccc;
  border-radius: 3px;
  background-color: white;
  cursor: pointer;
}

.paymodbtn:hover {
  background-color: orange;
  color: white;
}

.paymodbtn[selected="true"] {
  background-color: #f60;
  color: white;
}

.promoBox {
  border-radius: 8px;
  border: solid 6px #47ffb2;
  padding: 8px;
  background-color: #c1ffe5;
}

#sellerContract {
  width: 100%;
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 16px;
  font-size: 13px;
  background-color: #d9edf7;
  border-radius: 6px;
  padding: 20px;
  border: none;
}

#sellerContract:focus {
  outline: none;
}
</style>
