<template>
  <main>
    <br />
    <div id="chooseOffer">
      <div id="chooseOfferBoard" class="row justify-content-center">
        <div class="chooseOfferItem">
          <img src="@/assets/images/offer.png" />
          <div class="chooseOfferItemContent">
            <h4 id="chooseOfferTitle">
              Service: {{ service.type }} {{ service.label }}
            </h4>
            <p id="chooseOfferTitle">
              {{ service.help }}
            </p>
          </div>
        </div>

        <div class="chooseOfferItem">
          <div class="chooseOfferItemContent">
            <h4 id="chooseOfferTitle" class="mb-3">Choix du nom de domaine</h4>

            <!-- <div class="d-flex w-100 align-items-center mb-3">
              <select id="chooseOfferModality" v-model="offerModality">
                <option value="0">Offre annuelle</option>
                <option value="1">Offre semestrielle</option>
                <option value="2">Offre trimestrielle</option>
                <option value="3">Offre mensuelle</option>
              </select>

              <h4>
                <span class="ms-2 badge bg-success mt-2"
                  >{{
                    correctPrice(
                      offerModality == 0
                        ? service.price_per_year
                        : offerModality == 1
                        ? service.price_per_semester
                        : offerModality == 2
                        ? service.price_per_quarter
                        : service.price_per_month
                    )
                  }}
                  Fcfa HT</span
                >
              </h4>
            </div> -->

            <form
              @submit.prevent="__checkDomain()"
              v-if="hasDomain == 1 || (hasDomain == 2 && !domainExists)"
            >
              <div class="row mb-3 ps-2">
                <div
                  class="form-floating col-12 col-md-6 px-1"
                  id="chooseOfferDomainBox"
                >
                  <input
                    id="chooseOfferDomain"
                    v-model="domainName"
                    class="form-control"
                    placeholder="Entrez votre nom de domaine..."
                    @input="resetDomainState()"
                  />
                  <label for="chooseOfferDomain">Nom de domaine</label>
                </div>

                <div class="col-12 col-md-6 mb-3 text-start">
                  <div id="chooseOfferDomainExists" show="false">
                    <i class="bi bi-x-circle me-2"></i>
                    <a> Nom de domaine déjà réservé.</a>
                  </div>
                  <div id="chooseOfferDomainNotExists" show="false">
                    <i class="bi bi-check-circle me-2"></i>
                    <a> Nom de domaine libre. </a>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                id="chooseOfferCheckDomainBtn"
                class="btn btn-primary float-start"
              >
                Verifier le nom de Domaine
              </button>
              <br />
              <br />
            </form>

            <div v-if="hasDomain == 2" class="text-start">
              <input
                id="confirmOrderChecker"
                type="checkbox"
                v-model="domainExists"
              />
              <label for="confirmOrderChecker" class="ms-1"
                ><b>J'ai déjà un nom de domaine</b></label
              >
            </div>
          </div>
        </div>
      </div>

      <div id="coBtns" class="btn-group btn-group-toggle" data-toggle="buttons">
        <button
          @click="back()"
          class="chooseOfferItemBackBtn btn btn-secondary"
        >
          <i class="bi bi-arrow-left"></i>
        </button>
        <button
          @click="__validate()"
          class="chooseOfferItemValidateBtn btn btn-warning"
        >
          Poursuivre la Commande<i class="bi bi-caret-right-fill"></i>
        </button>
      </div>

      <br />
      <br />
      <br />
    </div>
  </main>
</template>

<script>
const $ = require("jquery");
import axios from "axios";
import Globals from "/src/common/js/Globals";
import functions from "/src/common/js/functions";
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
import localforage from "localforage";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";

//chooseOffer component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data: function () {
    return {
      pageName: "choix du nom de domaine",
      service: {},
      offerId: -1,

      offerModality: "Y",
      offerModalityByMonth: [1],
      offerModalityByYear: [1],

      domainName: "",
      domain: "",
      domainUrl: "",

      hasDomain: 1,
      domainExists: false,
    };
  },

  mounted() {
    waitLoader.show();
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;

    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        waitLoader.hide();
      }, 1);
    },

    update() {
      this.hasDomain = this.usersStore.User.account.hasDomain;
      //offerModality
      // this.offerModality = this.usersStore.User.account.curOfferModality ?? 0;
      //get all services in json
      const getSelectedService = async () => {
        try {
          let eid = functions.correctQueryString(
            this.usersStore.User.data.enterprise_id + ""
          );
          let serviceId = functions.correctQueryString(
            "" + this.usersStore.User.account.curServiceId
          );
          const resp = await axios.get(
            Globals.website_url +
              "php/api.php?query=get-service-by-id/" +
              eid +
              "/" +
              serviceId
          );
          this.service = resp.data;
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      };
      getSelectedService();
    },

    correctPrice(price) {
      return functions.correctPrice(price);
    },

    setOfferId() {
      //
      this.offerId = $("#chooseOfferModality")[0].selectedIndex - 1;
      //get the last selected option for any changes
      if (this.offerId == 0) {
        this.setOfferModalityId();
      }
      if (this.offerId == 1) {
        this.setOfferModality2Id();
      }
    },

    back() {
      this.$router.back();
    },

    __checkDomain() {
      waitLoader.show();
      //
      $("#chooseOfferDomainExists")[0].setAttribute("show", "false");
      $("#chooseOfferDomainNotExists")[0].setAttribute("show", "false");
      $("#chooseOfferDomainNotExists a")[0].innerHTML = "";
      //
      setTimeout(() => {
        this.__EC_checkDomain();
      }, 100);
    },

    __EC_checkDomain() {
      let domain = this.domainName.toLowerCase();
      //
      if (
        !/^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/.test(
          domain
        )
      ) {
        waitLoader.hide();
        //
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Veuillez saisir un nom de domaine valide (3 caract. minimum) suivi d'une extension (ex: .com)."
        );
        popup.setButton("Ok");
        popup.show();
        return false;
      }
      //
      let h = functions.syncHttpGet(
        Globals.website_url + "php/domain/checkDomain.php?domain=" + domain
      );
      //
      try {
        h = JSON.parse(h);
      } catch (e) {
        waitLoader.hide();
        //
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage("Problème de vérification! Veuillez reéssayer.");
        popup.setButton("Ok");
        popup.show();
        //
        waitLoader.hide();
        return false;
      }
      //
      if (!h.success) {
        $("#chooseOfferDomainExists")[0].setAttribute("show", "false");
        $("#chooseOfferDomainNotExists")[0].setAttribute("show", "false");
        //
        if (h.code == "domain_premium") {
          popup.setMessage(
            "Pour les noms de domaines prémium, veuillez contacter l'assistance de Ecoweb."
          );
        } else {
          popup.setMessage(
            "Problème de vérification du nom de domaine! Veuillez reéssayer."
          );
        }
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setButton("Ok");
        popup.show();
        //
        waitLoader.hide();
        return false;
      }
      //FB Pixel event
      window.fbq("trackCustom", "CheckOfferDomain", {
        domain: domain,
        available: h.data[domain].availability,
      });
      //Google Analytics
      this.$gtag.event("CheckOfferDomain", {
        domain: domain,
        available: h.data[domain].availability,
      });
      //
      if (h.data[domain].availability) {
        $("#chooseOfferDomainExists")[0].setAttribute("show", "false");
        $("#chooseOfferDomainNotExists")[0].setAttribute("show", "true");
        $("#chooseOfferDomainNotExists a")[0].innerHTML =
          "Nom de domaine libre.";
        //
        waitLoader.hide();
        return true;
      } else {
        $("#chooseOfferDomainExists")[0].setAttribute("show", "true");
        $("#chooseOfferDomainNotExists")[0].setAttribute("show", "false");
        //
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Ce nom de domaine est déjà réservé! Veuillez en choisir un autre."
        );
        popup.setButton("Ok");
        popup.show();
        //
        waitLoader.hide();
        return false;
      }
    },

    resetDomainState() {
      // $("#chooseOfferDomainExists")[0].setAttribute("show", "false");
      // $("#chooseOfferDomainNotExists")[0].setAttribute("show", "false");
      // //
      // let suffix = $("#domainSuffix option:selected").text();
      // this.domain = $("#chooseOfferDomain")[0].value.toLowerCase();
      // this.domainUrl = this.domain + suffix;
    },

    __validate() {
      let domain = this.domainName;

      if (this.hasDomain != 2 || (this.hasDomain == 2 && !this.domainExists)) {
        if (
          domain.length < 3 ||
          domain.indexOf(".") < 2 ||
          domain.indexOf(".") > domain.length - 2
        ) {
          //
          waitLoader.hide();
          //
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Veuillez saisir un nom de domaine valide (2 caractères minimum) suivi d'une extension (ex: .com)."
          );
          popup.setButton("Ok");
          popup.show();
          //
          return;
        }
      }

      waitLoader.show();
      setTimeout(() => {
        if (!this.domainExists) {
          //check domain availability
          let h = this.__EC_checkDomain();
          if (!h) return;
        }

        this.validate();
      }, 100);
    },

    async validate() {
      let domain = this.domainName;
      //on good infos store data into session data
      this.usersStore.User.account.curOfferDomain = !this.domainExists
        ? domain
        : "";
      this.usersStore.User.account.payOperationType = 2;
      //Offer modality
      this.usersStore.User.account.curOfferModality = this.offerModality;

      //save cache data
      try {
        await localforage.setItem(
          "ecocloud/User",
          JSON.stringify(this.usersStore.User)
        );
      } catch (err) {
        // This code runs if there were any errors.
      }
      //
      waitLoader.hide();
      //on success
      this.$router.push("/client/payment");
    },
  },
};
</script>

<style scoped>
.badge {
  cursor: default !important;
}

/*------------------------
CHOOSE OFFER ABOUT
-------------------------*/
#chooseOffer {
  max-width: 840px;
  width: 100%;
  margin: auto;
  font-size: 16px;
}

.chooseOfferItem {
  cursor: pointer;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 32px;
  padding: 24px 24px 24px 24px;
  width: 100%;
}

.chooseOfferItem img {
  float: left;
  width: 80px;
  height: 80px;
  margin-right: 16px;
}

.chooseOfferItemContent {
  width: 100%;
  height: max-content;
}

.chooseOfferItemContent p {
  width: 100%;
  text-align: center;
}

.chooseOfferItemContent select {
  float: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 5px;
  background-color: white;
  padding: 4px 16px;
  padding-right: 32px;
  /**/
  border: solid 1px #ddd;
  border-radius: 4px;
}

.chooseOfferItemBackBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}

.chooseOfferItemValidateBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}

#chooseOfferDomainBox {
}

#chooseOfferDomain {
  width: 100%;
  margin-right: 16px;
}

#domainSuffixBox {
}

#domainSuffix {
  width: 100%;
}

#chooseOfferDomainNotExists {
  float: left;
  font-size: 32px;
  color: green;
  position: relative;
  left: 8px;
  top: 0px;
}

#chooseOfferDomainNotExists a {
  font-size: 16px;
  position: relative;
  top: -8px;
}

#chooseOfferDomainExists {
  float: left;
  font-size: 32px;
  color: #c00;
  position: relative;
  left: 8px;
  top: 0px;
}

#chooseOfferDomainExists a {
  font-size: 16px;
  position: relative;
  top: -8px;
}

#chooseOfferDomainExists[show="false"] {
  display: none;
}

#chooseOfferDomainNotExists[show="false"] {
  display: none;
}

#chooseOfferCheckDomainBtn {
  width: max-content;
}

#coBtns button:first-child {
  max-width: 44px;
}

@media only screen and (max-width: 480px) {
  .chooseOfferItem {
    text-align: center;
  }

  .chooseOfferItem img {
    float: none;
    margin-right: 0;
  }

  .chooseOfferItemContent {
    float: none;
  }

  .chooseOfferItemContent select {
    float: none;
  }
}

@media only screen and (max-width: 316px) {
}
</style>
